import { ChildComponentGenerator } from 'components/ContentHandlers'
import dynamic from 'next/dynamic'

const GraphicCardList = dynamic(() => import('components/Graphic/GraphicCardList/GraphicCardList'))
const GraphicCommentBubble = dynamic(() =>
  import('components/Graphic/GraphicCommentBubble/GraphicCommentBubble')
)

export const sectionImagesTypes = {
  GraphicCardList,
  GraphicCommentBubble,
}

export const GraphicHandler = ({ imageComponent, ...props }) => {
  if (!imageComponent) return <></>
  return (
    <ChildComponentGenerator
      contentTypes={sectionImagesTypes}
      uiComponent={imageComponent}
      {...props}
    />
  )
}
